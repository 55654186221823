<template>
  <v-dialog
    max-width="800"
    :value="value"
    @input="updateValue"
    @click:outside="abort"
    ref="organizationDialog"
  >
    <v-card>
      <v-card-title v-if="!stepsCompleted">
        {{ $t('newOrganization') }}
      </v-card-title>
      <v-progress-linear
        v-if="stepsCompleted"
        indeterminate
      />
      <v-card-title v-if="stepsCompleted">
        {{ $t('creatingOrganization') }}
      </v-card-title>
      <v-card-subtitle v-if="stepsCompleted">
        {{ $t('thisMayTakeAMoment') }}
      </v-card-subtitle>
      <v-stepper
        v-if="!stepsCompleted"
        flat
        light
        v-model="e1"
      >
        <template>
          <v-stepper-header>
            <template v-for="(step, n) in steps">
              <v-stepper-step
                :key="`${n}-step`"
                :complete="e1 > n"
                :step="n"
              >
                {{ step.title }}
              </v-stepper-step>

              <v-divider
                v-if="n !== steps"
                :key="n"
              />
            </template>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content
              v-for="(step, n) in steps"
              :key="`${n}-content`"
              :step="n"
            >
              <template v-if="step.key === 'general'">
                <v-text-field
                  filled
                  type="text"
                  :label="$t('name')"
                  v-model="organization.label"
                  required
                  :error="fieldErrors.name.length > 0"
                  :error-messages="fieldErrors.name"
                />
                <v-text-field
                  filled
                  type="phone"
                  :label="$t('phone')"
                  v-model="organization.phone"
                  required
                  :error="fieldErrors.phone.length > 0"
                  :error-messages="fieldErrors.phone"
                />
                <v-text-field
                  filled
                  type="email"
                  :label="$t('email')"
                  v-model="organization.email"
                  required
                  :error="fieldErrors.email.length > 0"
                  :error-messages="fieldErrors.email"
                />
              </template>

              <template v-if="step.key === 'address'">
                <v-text-field
                  filled
                  type="text"
                  :label="$t('street')"
                  v-model="organization.address.thoroughfare"
                  required
                  :error="fieldErrors.thoroughfare.length > 0"
                  :error-messages="fieldErrors.thoroughfare"
                />
                <v-text-field
                  filled
                  type="text"
                  :label="$t('postalCode')"
                  v-model="organization.address.postal_code"
                  required
                  :error="fieldErrors.postal_code.length > 0"
                  :error-messages="fieldErrors.postal_code"
                />
                <v-text-field
                  filled
                  type="text"
                  :label="$t('city')"
                  v-model="organization.address.locality"
                  required
                  :error="fieldErrors.locality.length > 0"
                  :error-messages="fieldErrors.locality"
                />
                <v-select
                  filled
                  :label="$t('country')"
                  :items="countries"
                  item-text="name"
                  item-value="code"
                  v-model="organization.address.country"
                  required
                  :error="fieldErrors.country.length > 0"
                  :error-messages="fieldErrors.country"
                />
              </template>

              <template v-if="step.key === 'currencies'">
                <v-select
                  multiple
                  filled
                  :label="$t('currencies')"
                  :items="currencies"
                  item-text="name"
                  item-value="code"
                  v-model="organization.currencies"
                  required
                  :error="fieldErrors.currencies.length > 0"
                  :error-messages="fieldErrors.currencies"
                />
                <v-select
                  filled
                  :label="$t('defaultCurrency')"
                  :items="currencies"
                  item-text="name"
                  item-value="code"
                  v-model="organization.default_currency"
                  required
                  :error="fieldErrors.default_currency.length > 0"
                  :error-messages="fieldErrors.default_currency"
                />
              </template>

              <template v-if="step.key === 'tax'">
                <v-text-field
                  filled
                  type="text"
                  :label="$t('taxId')"
                  v-model="organization.tax_id"
                  required
                  :error="fieldErrors.tax_id.length > 0"
                  :error-messages="fieldErrors.tax_id"
                />
                <v-text-field
                  filled
                  type="text"
                  :label="$t('vatId')"
                  v-model="organization.vat_id"
                  required
                  :error="fieldErrors.vat_id.length > 0"
                  :error-messages="fieldErrors.vat_id"
                />
                <v-select
                  multiple
                  filled
                  :label="$t('vatBrackets')"
                  :items="vat"
                  item-text="name"
                  item-value="code"
                  v-model="organization.vat"
                  required
                  :error="fieldErrors.vat.length > 0"
                  :error-messages="fieldErrors.vat"
                />
              </template>

              <template v-if="step.key === 'branding'">
                <v-row>
                  <v-col
                    cols="6"
                    cols-md="6"
                    cols-sm="12"
                  >
                    <div class="title">
                      {{ $t('primaryColor') }}
                    </div>
                    <v-color-picker
                      mode="hexa"
                      v-model="organization.primary_color"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    cols-md="6"
                    cols-sm="12"
                  >
                    <div class="title">
                      {{ $t('secondaryColor') }}
                    </div>
                    <v-color-picker
                      mode="hexa"
                      v-model="organization.accent_color"
                    />
                  </v-col>
                </v-row>
              </template>
            </v-stepper-content>
          </v-stepper-items>
        </template>
      </v-stepper>
      <v-card-actions v-if="!stepsCompleted">
        <v-btn
          text
          color="error"
          large
          @click="abort"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          @click="previousStep"
          :disabled="e1 <= 0"
          large
        >
          <v-icon left>
            mdi-chevron-left
          </v-icon>
          {{ $t('previous') }}
        </v-btn>
        <v-btn
          color="primary"
          @click="nextStep"
          large
        >
          {{ $t('continue') }}
          <v-icon right>
            mdi-chevron-right
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * @property {object} organizationDialog
 */
import axios from 'axios'

export default {
	name: 'CreateOrganizationDialog',
	props: {
		value: {
      type: Boolean
    }
	},
	data () {
		return {
			vat: [
				{
					name: '11%',
					code: '0.11'
				},
				{
					name: '24%',
					code: '0.24'
				}
			],
			organization: {
				label: '',
				phone: '',
				email: '',
				address: {
					thoroughfare: '',
					postal_code: '',
					locality: '',
					country: 'IS'
				},
				tax_id: '',
				vat_id: '',
				default_currency: 'ISK',
				vat: [],
				currencies: ['ISK'],
				default_language: '',
				primary_color: '#2277DD',
				accent_color: '#2277DD'
			},
			fieldErrors: {
				name: [],
				phone: [],
				email: [],
				country: [],
				thoroughfare: [],
				postal_code: [],
				locality: [],
				primary_color: [],
				accent_color: [],
				tax_id: [],
				vat_id: [],
				vat: [],
				currencies: [],
				default_currency: []
			},
			e1: 0,
			steps: [
				{
					title: this.$t('organizationInfo'),
					key: 'general',
					validate: function() {
						let validates = true

						this.fieldErrors.name = []
						this.fieldErrors.email = []
						this.fieldErrors.phone = []

						if(this.organization.label === null || this.organization.label === '') {
							validates = false
							this.fieldErrors.name.push(this.$t('nameCannotBeEmpty'))
						}

						if(this.organization.phone === null || this.organization.phone === '') {
							validates = false
							this.fieldErrors.phone.push(this.$t('phoneCannotBeEmpty'))
						}

						if(this.organization.email === null || this.organization.email === '') {
							validates = false
							this.fieldErrors.email.push(this.$t('emailCannotBeEmpty'))
						}

						return validates
					}.bind(this)
				},
				{
					title: this.$t('address'),
					key: 'address',
					validate: function() {

						let validates = true

						this.fieldErrors.thoroughfare = []
						this.fieldErrors.locality = []
						this.fieldErrors.postal_code = []
						this.fieldErrors.country = []

						if(this.organization.address.country === null || this.organization.address.country === '') {
							validates = false
							this.fieldErrors.country.push(this.$t('countryCannotBeEmpty'))
						}

						if(this.organization.address.thoroughfare === null || this.organization.address.thoroughfare === '') {
							validates = false
							this.fieldErrors.thoroughfare.push(this.$t('streetCannotBeEmpty'))
						}

						if(this.organization.address.locality === null || this.organization.address.locality === '') {
							validates = false
							this.fieldErrors.locality.push(this.$t('cityCannotBeEmpty'))
						}

						if(this.organization.address.postal_code === null || this.organization.address.postal_code === '') {
							validates = false
							this.fieldErrors.postal_code.push(this.$t('postalCodeCannotBeEmpty'))
						}

						return validates
					}.bind(this)
				},
				{
					title: this.$t('currencies'),
					key: 'currencies',
					validate: function() {

						let validates = true

						this.fieldErrors.currencies = []

						if(this.organization.currencies.length === 0) {
							validates = false
							this.fieldErrors.currencies.push(this.$t('atLeastOneCurrencyMustBeSelected'))
						}

						return validates

					}.bind(this)
				},
				{
					title: this.$t('tax'),
					key: 'tax',
					validate: function() {

						let validates = true

						this.fieldErrors.tax_id = []
						this.fieldErrors.vat_id = []
						this.fieldErrors.vat = []

						if(this.organization.tax_id === null || this.organization.tax_id === '') {
							validates = false
							this.fieldErrors.tax_id.push(this.$t('taxIdCannotBeEmpty'))
						}

						if(this.organization.vat.length > 0 && (this.organization.vat_id === null || this.organization.vat_id === '')) {
							validates = false
							this.fieldErrors.vat_id.push(this.$t('vatIdCannotBeEmpty'))
						}

						if(this.organization.vat_id === '' && this.organization.vat.length > 0) {
							validates = false
							this.fieldErrors.vat_id.push(this.$t('vatCannotBeEmpty'))
						}

						return validates

					}.bind(this)
				},
				{
					title: this.$t('branding'),
					key: 'branding',
					validate: function() {

						return true
					}
				}
			]
		}
	},
	methods: {
		updateValue() {
			this.$emit('input', this.$refs.organizationDialog.value)
		},
		abort() {
			this.$emit('input', false)
		},
		previousStep() {
			const step = this.steps[this.e1]
			if(step) {
				if(step.validate && step.validate()) {
					this.e1--
				} else if(!step.validate) {
					this.e1--
				}
			}
		},
		nextStep () {
			const step = this.steps[this.e1]

			if(step) {
				if(step.validate && step.validate()) {
					this.e1++
				} else if(!step.validate) {
					this.e1++
				}
			}

			if(this.stepsCompleted) {
				axios.post(this.graphUrl, {
					query: 'mutation createOrganization($dm: NewOrganization){\n' +
							'  createOrganization(organization:$dm) {\n' +
							'       uuid\n' +
							'       label\n' +
							'  }\n' +
							'}',
					variables: {
						dm: this.organization
					},
				}, {
          params: {
            time: new Date().getTime()
          }
        }).then(response => {
					if(response.data.data.createOrganization) {
						this.value = false
						this.$store.dispatch('getOrganizations').then(() => {
							this.$emit('created')
							this.e1 = 0
						})
					} else {
						this.$emit('error', response.data.errors)
					}
				}).catch(error => {
					this.$emit('error', error.message)
				})
			}

		}
	},
	computed: {
		showDialog: {
			get() {
				return this.$store.state.showCreateOrganizationDialog
			},
			set(value) {
				this.$store.commit('updateShowCreateOrganizationDialog', value)
			}

		},
		graphUrl: function() {
			return this.$store.state.graphUrl
		},
		stepsCompleted: function() {
			return this.steps.length <= this.e1
		},
		countries: function() {
			return this.$store.state.countries
		},
		currencies: function() {
			return this.$store.state.currencies
		}
	},
	mounted() {
		this.e1 = 0
	},
	watch: {
		value(value) {
			if(value === false) {
				this.e1 = 0
			}
		}
	}
}
</script>

<style>

</style>
